import * as yup from "yup";
import { ANSWER_LAYOUT_TYPES } from "../../../helpers/constants";
import { PhoneNumberUtil } from "google-libphonenumber";
import { ReviewAnswerInputLayout } from "./ReviewAnswerInputLayout";
import { useContext } from "react";
import { AuthenticationContext } from "../../login";
import { useFormContext } from "react-hook-form";

const phoneNumberUtil = PhoneNumberUtil.getInstance();

// const FORM_FIELDS_NAMES = {
//   commentText: "comment_text",
//   name: "name",
//   phone: "phone",
// };

const commentTextMaxChars = 500;

// const egyPhoneRegex = /^01[0125][0-9]{8}$/gm;
export const nigeriaPhoneRegex = /^(\d{13})?$/gm;

// export function getRelativePhoneNumberRegex(accountID) {
//   switch (accountID) {
//     case PROFILE_IDS.Nigeria: {
//       return nigeriaPhoneRegex;
//     }
//     default: {
//       return egyPhoneRegex;
//     }
//   }
// }
const countryCodeAlpha2ToDialCode = {
  EG: 20,
  NG: 234,
};
export const useGetSharedReviewComplaintYupValidations = ({
  country,
  makeTextCommentRequired,
  target,
}) => {
  const { user } = useContext(AuthenticationContext);

  const sharedValidations = {
    name: yup.string().required("Required"),
    phone: yup
      .string()
      .required("Required")
      .test(
        "isValidPhoneNumber",
        "please enter a valid phone number",
        (value) => {
          if (country === "NG") return value.match(nigeriaPhoneRegex);
          else
            return phoneNumberUtil.isValidNumber(
              phoneNumberUtil.parse(value, country)
            );
        }
      ),
  };

  const contactsFieldsSettings =
    user.profile.input_settings.contact_fields[`${target}`];
  const nameContactSettings = contactsFieldsSettings.find(
    function isNameContactSetting(contactField) {
      return contactField.name === "name";
    }
  );
  const phoneContactSettings = contactsFieldsSettings.find(
    function isPhoneContactSetting(contactField) {
      return contactField.name === "phone";
    }
  );

  // Start: Make Name and/or Phone inputs optional if needed 
  if (!nameContactSettings.required) {
    sharedValidations.name = yup.string().optional();
  }
  if (!phoneContactSettings.required) {
    sharedValidations.phone = yup
      .string()
      .test(
        "isValidPhoneNumber",
        "please enter a valid phone number",
        (value) => {
          const valueWithoutDialCode = value?.replace(
            countryCodeAlpha2ToDialCode[`${country}`], ''
          );

          if (valueWithoutDialCode === "" || value === undefined) {
            // Pass the test since it's an optional field now
            return true;
          } else {
            // Test it if it's not empty
            if (country === "NG") return value.match(nigeriaPhoneRegex);
            else
              return phoneNumberUtil.isValidNumber(
                phoneNumberUtil.parse(value, country)
              );
          }
        }
      )
      .optional();
  }
  // End: Make Name and/or Phone inputs optional if needed

  if (makeTextCommentRequired)
    sharedValidations["comment_text"] = yup
      .string()
      .required("Required")
      .max(commentTextMaxChars, "Exceeded Max Characters (500)");
  else
    sharedValidations["comment_text"] = yup
      .string()
      .optional()
      .max(commentTextMaxChars, "Exceeded Max Characters (500)");

  return sharedValidations;
};

export const SharedFeedbackInputs = ({ resetField, country }) => {
  const sharedFeedbackQuestions = [
    {
      name: "comment_text",
      answer_type: ANSWER_LAYOUT_TYPES.long_text,
      question_text: "What could be better?",
      required: true,
      details: {
        placeholder: "Type customer answer...",
      },
    },
    {
      name: "name",
      answer_type: ANSWER_LAYOUT_TYPES.short_text,
      question_text: "Name",
      details: {
        placeholder: "Type customer answer...",
      },
    },
    {
      name: "phone",
      answer_type: ANSWER_LAYOUT_TYPES.phone_number,
      question_text: "Phone Number",
      details: {
        country: country,
      },
    },
  ];
  return sharedFeedbackQuestions.map((questionRawData, index) => {
    return (
      <ReviewAnswerInputLayout
        key={index}
        question={questionRawData}
        answerInput={{
          name: questionRawData.name,
        }}
        resetField={resetField}
        lastQuestion={sharedFeedbackQuestions.length - 1 === index}
      />
    );
  });
};
