import React, { useState, useRef, useEffect } from "react";
import { DateRangePicker } from "react-date-range";

import { ArrowRightAlt, ClearOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Skeleton, Typography } from "@mui/material";

import { COLORS } from "../../helpers/Colors";
import moment from "moment";
import useOnClickOutside from "../hooks/useOnClickOutside";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

/**
 * Memoized to prevent re-rendering in all cases, since after any re-render,
 * all the actions taken are cancelled, like mmoving back and forth to select specific days
 */
export const DateRangePickerInput = React.memo(
  ({
    callbackHandler,
    defaultDate,
    resetDateHandler,
    oneMonthView = false,
    skeleton = false,
    disabled = false,
  }) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [dateRangesData, setDateRangesData] = useState([
      {
        startDate: defaultDate.startDate ? defaultDate.startDate : new Date(),
        endDate: defaultDate.endDate ? defaultDate.endDate : new Date(),
        key: "selection",
      },
    ]);
    const ChosenDatesView = () => (
      <Button
        sx={{
          width: "18rem",
          height: "40px",
          paddingX: "0.5rem",
          paddingY: "0.5rem",
          borderRadius: "5px",
          border: "1px solid rgb(196 196 196)",
          textTransform: "capitalize",
          color: "#666666",
          "&:hover": {
            borderColor: "black",
            backgroundColor: "transparent",
          },
          opacity: disabled ? 0.5 : 1,
        }}
        onClick={() => setShowCalendar(!showCalendar)}
        disabled={disabled}
      >
        {/* Not using the local state, because local state is set to current day
            to not make the highlighted range covering all days
        */}
        {!defaultDate.startDate || !defaultDate.endDate ? (
          <Typography fontSize="0.9rem">Select Date Range</Typography>
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            color="black"
          >
            <Box display="flex" alignItems="center">
              <Typography id="fromDate" sx={{ fontSize: "0.9rem" }}>
                {moment(dateRangesData[0].startDate).format("MMM DD, YYYY")}
              </Typography>
            </Box>

            <Box display="flex" marginX="0.5rem">
              <ArrowRightAlt sx={{ fontSize: "1.5rem" }} />
            </Box>

            <Box display="flex" alignItems="center">
              <Typography id="toDate" sx={{ fontSize: "0.9rem" }}>
                {moment(dateRangesData[0].endDate).format("MMM DD, YYYY")}
              </Typography>
            </Box>
            {resetDateHandler && (
              <IconButton
                title="Clear Selected Date Range"
                onClick={(e) => {
                  e.stopPropagation();
                  resetDateHandler();
                }}
                color="default"
                component="span"
                sx={{
                  marginLeft: "1rem",
                  width: "15px",
                  //   "&:hover": { backgroundColor: "transparent" },
                }}
              >
                <ClearOutlined fontSize="small" />
              </IconButton>
            )}
          </Box>
        )}
      </Button>
    );

    const clickedOutsideDatepickerHandler = () => {
      setShowCalendar(false);
    };

    const dateElementRef = useRef(null);
    useOnClickOutside(dateElementRef, clickedOutsideDatepickerHandler);

    const updateDateHandler = (item) => {
      if (item.selection.endDate && item.selection.startDate) {
        const newRangesData = [item.selection];
        setDateRangesData(newRangesData);
        if (
          newRangesData[0].startDate &&
          newRangesData[0].endDate &&
          newRangesData[0].startDate !== newRangesData[0].endDate
        ) {
          callbackHandler({
            from: +newRangesData[0].startDate,
            to: +newRangesData[0].endDate,
          });
          setShowCalendar(false);
        }
      }
    };

    useEffect(() => {
      setDateRangesData([
        {
          startDate: defaultDate.startDate ? defaultDate.startDate : new Date(),
          endDate: defaultDate.endDate ? defaultDate.endDate : new Date(),
          key: "selection",
        },
      ]);
    }, [defaultDate.endDate, defaultDate.startDate]);

    return (
      <Box ref={dateElementRef} position="relative">
        <Box display="flex">
          {skeleton ? (
            <Skeleton
              variant="rounded"
              animation="wave"
              width={250}
              height={30}
            />
          ) : (
            <ChosenDatesView />
          )}
        </Box>
        {showCalendar && (
          <Box position="absolute" boxShadow={5} zIndex={10} top="4rem">
            <DateRangePicker
              onChange={updateDateHandler}
              showPreview={true}
              moveRangeOnFirstSelection={false}
              months={oneMonthView ? 1 : 2}
              ranges={dateRangesData}
              direction="horizontal"
              // color={COLORS.meliorYellow}
              rangeColors={[COLORS.meliorYellow]}
            />
          </Box>
        )}
      </Box>
    );
  },
  () => true
);
