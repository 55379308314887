import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useFeedback } from "../hooks/useFeedback";
import {
  SharedFeedbackInputs,
  useGetSharedReviewComplaintYupValidations,
} from "./SharedFeedbackInputs";
import { useReactHookFormWithYup } from "../../../reusable/hooks";
import moment from "moment";
import { submitReviewInternally, getTemplateQuestions } from "../api";
import { useContext } from "react";
import { AuthenticationContext } from "../../login";
import { ReviewAnswerInputLayout } from "./ReviewAnswerInputLayout";
import { primaryColors } from "../../../helpers/customColors";
import {
  fontWeightVariations,
} from "../../../helpers/customFont";
import { PhoneNumberUtil } from "google-libphonenumber";
import { ANSWER_LAYOUT_TYPES } from "../../../helpers/constants";

const Review = ({ templateID, divisionID }) => {
  const phoneNumberUtil = PhoneNumberUtil.getInstance();

  const [templateQuestions, setTemplateQuestions] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState(undefined);
  const [submitting, setSubmitting] = useState(false);
  const { feedbackUI, setSuccess, setError } = useFeedback();

  const { user } = useContext(AuthenticationContext);

  const getAndPrepareTemplateQuestions = (templateID) => {
    setLoadingQuestions(true);
    getTemplateQuestions({
      selectedTemplate: templateID,
      isMminimalized: true,
    })
      .then((templateQuestionsResponse) => {
        setLoadingQuestions(false);
        setTemplateQuestions([...templateQuestionsResponse]);
      })
      .catch((error) => console.log(error));
  };

  // Q&A form YUP validation computed from resolved(fetched) template questions data.
  const questionsValidation = {};
  templateQuestions.forEach((question) => {
    if (question.required) {
      if (question.answer_type === ANSWER_LAYOUT_TYPES.phone_number)
        questionsValidation[`question-${question.id}`] = yup
          .string()
          .required("Required")
          .test(
            "isValidPhoneNumber",
            "please enter a valid phone number",
            (value) =>
              phoneNumberUtil.isValidNumber(
                phoneNumberUtil.parse(value, user.profile.country)
              )
          );
      else
        questionsValidation[`question-${question.id}`] = yup
          .string()
          .required("Required");
    }
  });

  const validationSchema = yup.object({
    ...questionsValidation,
    ...useGetSharedReviewComplaintYupValidations({
      country: user.profile.country,
      makeTextCommentRequired: false,
      target: 'review'
    }),
  });

  const reactHookFormMethods = useReactHookFormWithYup({
    validationSchema,
    useFormProps: {
      mode: "onChange",
    },
    defaultValues: {
        phone: '',
    }
  });
  const { reset, handleSubmit, formState, resetField } = reactHookFormMethods;

  useEffect(() => {
    // To investigate: next line is mandatory (it shouldn't be),
    // otherwise, the form data will accumilate after every re-fetch
    setTemplateQuestions([]);

    if (templateID !== undefined) getAndPrepareTemplateQuestions(templateID);

    reset();
  }, [templateID, reset]);

  // Temp Logging for the submitted reviews.
  // Only to set initial data, for main object or for inner objects.
  useEffect(() => {
    //1- Get the current day logging key string
    const currentDayLoggingKeyString = computeCurrentDayLoggingKeyString();

    //2- Add the key with zeroes in case it doesn't exist.
    const stringifiedReviewsLogsData = localStorage.getItem("reviews-logs");
    if (stringifiedReviewsLogsData === null) {
      const initialReviewsLogsData = {};
      initialReviewsLogsData[currentDayLoggingKeyString] = initialLoggingData;
      localStorage.setItem(
        "reviews-logs",
        JSON.stringify(initialReviewsLogsData)
      );
    } else {
      // Main key exists.
      // Add the internal day's key if it doesn't exist
      const reviewsLogsDataWithNewDay = JSON.parse(stringifiedReviewsLogsData);
      if (reviewsLogsDataWithNewDay[currentDayLoggingKeyString] === undefined) {
        reviewsLogsDataWithNewDay[currentDayLoggingKeyString] =
          initialLoggingData;
        localStorage.setItem(
          "reviews-logs",
          JSON.stringify(reviewsLogsDataWithNewDay)
        );
      }
    }
  }, []);

  // Function to create the current day logging key string
  const computeCurrentDayLoggingKeyString = () => {
    const currentMonth = moment().month() + 1;
    const currentDay = moment().date();

    return `${currentDay}_${currentMonth}`;
  };

  // Funtion to get the current key with its values
  const getCurrentDayLoggingObject = () => {
    const currentDayLoggingKeyString = computeCurrentDayLoggingKeyString();

    const reviewsLoggingData = JSON.parse(localStorage.getItem("reviews-logs"));
    const currentDayLoggingData =
      reviewsLoggingData[currentDayLoggingKeyString];

    if (currentDayLoggingData !== undefined) {
      return currentDayLoggingData;
    } else {
      return initialLoggingData;
    }
  };

  const updateDayReviewsLogs = (updatedReviewsLogs, error = undefined) => {
    const currentDayLoggingKeyString = computeCurrentDayLoggingKeyString();
    const reviewsLoggingData = JSON.parse(localStorage.getItem("reviews-logs"));

    const updatedReviewsLoggingData = reviewsLoggingData;
    updatedReviewsLoggingData[currentDayLoggingKeyString] = {
      ...updatedReviewsLogs,
    };

    if (error !== undefined) {
      updatedReviewsLoggingData[currentDayLoggingKeyString].errors.push(error);
    }

    localStorage.setItem(
      "reviews-logs",
      JSON.stringify(updatedReviewsLoggingData)
    );
  };

  if (templateID === undefined) return <p>No template assigned...</p>;

  return loadingQuestions === true ? (
    <CircularProgress color="secondary" />
  ) : (
    <FormProvider {...reactHookFormMethods}>
      <form
        method="POST"
        onSubmit={handleSubmit(async (reviewFormData) => {
          // LOGS - Set as submitted
          const currentDayLoggingData = getCurrentDayLoggingObject();
          currentDayLoggingData.submittedReviews =
            currentDayLoggingData.submittedReviews + 1;
          updateDayReviewsLogs(currentDayLoggingData);

          setSubmitting(true);

          const preparedData = prepareReviewFormData({
            templateQuestions,
            reviewFormData,
            divisionID,
            templateID,
          });
          await submitReviewInternally(preparedData)
            .then((data) => {
              // LOGS - Set as submitted successfully
              const updatedCompletionLoggingData = getCurrentDayLoggingObject();
              updatedCompletionLoggingData.submissionCompleted =
                updatedCompletionLoggingData.submissionCompleted + 1;
              updateDayReviewsLogs(updatedCompletionLoggingData);

              setSubmitting(false);
              setSuccess(true);

              reset();
            })
            .catch((error) => {
              // LOGS - Set as submission is failed
              const updatedFaliureLoggingData = getCurrentDayLoggingObject();
              updatedFaliureLoggingData.submissionFailed =
                updatedFaliureLoggingData.submissionFailed + 1;
              updateDayReviewsLogs(updatedFaliureLoggingData, error);

              setSubmitting(false);
              setError(true);
            });
        })}
        style={{
          width: "100%",
        }}
      >
        <Box display="flex" flexDirection="column" px="1rem" gap="24px">
          {templateQuestions.map((questionRawData, index) => {
            return (
              <ReviewAnswerInputLayout
                key={index}
                question={questionRawData}
                answerInput={{
                  name: `question-${questionRawData.id}`,
                }}
                resetField={resetField}
                lastQuestion={templateQuestions.length - 1 === index}
              />
            );
          })}
          <SharedFeedbackInputs
            resetField={resetField}
            country={user.profile.country}
          />

          <Box display="flex" justifyContent="flex-end" mt="2rem" width="100%">
            <Button
              type="button"
              variant="outlined"
              onClick={function resetFormInputs() {
                reset();
              }}
              disabled={submitting || !formState.isDirty}
              sx={{
                borderRadius: "8px",
                border: `1px solid ${
                  submitting || !formState.isDirty
                    ? primaryColors.gray[200]
                    : primaryColors.gray[300]
                }`,
                marginRight: "1rem",
                textTransform: "capitalize",
                color:
                  submitting || !formState.isDirty
                    ? primaryColors.gray[300]
                    : primaryColors.base.black,
              }}
            >
              Reset
            </Button>
            <Button
              type="submit"
              disabled={submitting || !formState.isValid}
              sx={{
                borderRadius: "8px",
                backgroundColor:
                  submitting || !formState.isValid
                    ? primaryColors.brand[200]
                    : primaryColors.brand[500],
              }}
            >
              <Typography
                variant="text-sm"
                fontFamily="Nunito"
                textTransform="capitalize"
                fontWeight={fontWeightVariations.medium}
                color={primaryColors.base.white}
              >
                {submitting ? <CircularProgress size={20} /> : "Submit"}
              </Typography>
            </Button>
          </Box>

          {feedbackUI}
        </Box>
      </form>
    </FormProvider>
  );
};

const prepareAnswersResponse = ({ templateQuestions, reviewFormData }) => {
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  const preparedAnswersResponse = {
    choiceAnswers: [],
    inputAnswers: [],
    voiceAnswers: [],
  };

  for (var field in reviewFormData) {
    //Filter out fields that are not questions(name,phone&comment_text)
    if (field.includes("question-")) {
      // Filter out non-answered questions
      if (reviewFormData[field] !== undefined) {
        let questionId = field.replace("question-", "");
        let questionData = templateQuestions.find(
          (question) => question.id == questionId
        );

        switch (questionData.answer_type) {
          case ANSWER_LAYOUT_TYPES.multiple_choices:
          case ANSWER_LAYOUT_TYPES.nps: {
            preparedAnswersResponse.choiceAnswers.push({
              question: questionData.id,
              choice: reviewFormData[field],
            });
            break;
          }
          case ANSWER_LAYOUT_TYPES.voice_note: {
            base64regex.test(reviewFormData[field])
              ? preparedAnswersResponse.voiceAnswers.push({
                  question: questionData.id,
                  voice: reviewFormData[field],
                })
              : preparedAnswersResponse.voiceAnswers.push({
                  question: questionData.id,
                  text_version: reviewFormData[field],
                });
            break;
          }
          case ANSWER_LAYOUT_TYPES.long_text:
          case ANSWER_LAYOUT_TYPES.short_text:
          case ANSWER_LAYOUT_TYPES.numbers:
          case ANSWER_LAYOUT_TYPES.phone_number: {
            preparedAnswersResponse.inputAnswers.push({
              question: questionData.id,
              input: reviewFormData[field],
            });
            break;
          }
          default:
            break;
        }
      }
    }
  }

  return preparedAnswersResponse;
};

const prepareReviewFormData = ({
  templateQuestions,
  reviewFormData,
  divisionID,
  templateID,
}) => {
  return {
    ...prepareAnswersResponse({ templateQuestions, reviewFormData }),
    comment_text: reviewFormData.comment_text,
    contacts: {
      name: reviewFormData.name,
      phone: `+${reviewFormData.phone}`,
    },
    comment_audio: null,
    division: divisionID,
    nonce: uuidv4(),
    submitted_at: new Date().toISOString(),
    template: templateID,
  };
};

const initialLoggingData = {
  submittedReviews: 0,
  submissionCompleted: 0,
  submissionFailed: 0,
  errors: [],
};

export default Review;
