import { Controller, useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useState } from "react";
import { Box, FormHelperText } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";

export const FormPhoneNumberInput = ({
  name,
  country,
  //   sx,
  //   width,
}) => {
  const { control } = useFormContext();
  const [defaultValue, setDefaultValue] = useState();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => (
        <Box width="100%">
          <PhoneInput
            helperText={error ? error.message : null}
            value={value ? value : defaultValue}
            onChange={(value, country, event) => {
              event.target.value = value.split(" ").join("");
              onChange(event);
            }}
            disableDropdown
            country={country?.toLowerCase()}
            onMount={function setCountryCodeAsDefaultValue(value) {
              setDefaultValue(value);
            }}
            containerStyle={{
              width: "100%",
              height: "auto",
            }}
            inputStyle={{
              width: "100%",
            }}
            isValid={function isValidPhoneNumber() {
              return !invalid;
            }}
          />
          <Box display={"block"}>
            <FormHelperText style={{ color: primaryColors.error[500] }}>
              {error ? error.message : ""}
            </FormHelperText>
          </Box>
        </Box>
      )}
    />
  );
};
