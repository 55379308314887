import { Backdrop, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { primaryColors } from "../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../helpers/customFont";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";

export const PaymentStatusOverlay = ({
  currentPhaseIsGrace,
  currentPhaseIsDeadline,
}) => {
  const [openPaymentOverlay, setOpenPaymentOverlay] = useState(false);
  const [overlayClosingCountdown, setOverlayClosingCountdown] = useState(10);
  const [buttonsEnabled, setButtonsEnabled] = useState(true);

  const navigate = useNavigate();
  const gracePaymentStatusMessage =
    "Oh oh, it seems there are pending unsettled payments that passed their due time. Make sure these matters are settled as soon as possible or get in touch with us if you believe you should not be receiving this message.";
  const deadlinePaymentStatusMessage =
    "It seems your due payments are long overdue. Please settle your payments as soon as possible to make sure you can access all services without any disturbance. If you have any issues with our services or if you believe you should not be receiving these messages, please let us know. ";

  function saveOverlayLastActiveTimeToLocalStorage() {
    const currentTime = new Date();
    localStorage.setItem("lastTimeOverlayWasActive", currentTime);
  }
  function startOverlayCountdownAndRedirectIfNeeded({
    redirectToFeedbackTab = false,
  }) {
    setButtonsEnabled(false);

    const countdownInterval = setInterval(function decrementCountdown() {
      setOverlayClosingCountdown(
        (previousCountdownValue) => previousCountdownValue - 1
      );
    }, 1000);

    setTimeout(function closeOverlay() {
      clearInterval(countdownInterval);
      setOpenPaymentOverlay(false);
      saveOverlayLastActiveTimeToLocalStorage();
      setOverlayClosingCountdown(10);
      setButtonsEnabled(true);
      redirectToFeedbackTab && navigate("/settings/give-feedback");
    }, 10000);
  }
  function showPaymentOverlay() {
    setOpenPaymentOverlay(true);
  }

  useEffect(
    function showPaymentOverlayIfNotPreviouslyShownOrExceededCurrentPhaseInterval() {
      const lastTimeOverlayWasActive = localStorage.getItem(
        "lastTimeOverlayWasActive"
      );

      if (lastTimeOverlayWasActive === undefined) {
        showPaymentOverlay();
      } else {
        const currentTime = new Date();
        const lastOverlayActiveDate = new Date(lastTimeOverlayWasActive);
        const timeDifferenceInMinutes = Math.round(
          (currentTime.getTime() - lastOverlayActiveDate.getTime()) /
            (1000 * 60)
        );

        if (currentPhaseIsGrace && timeDifferenceInMinutes >= 1440) {
          //1440 minutes = 1 day
          showPaymentOverlay();
        }

        let reOpenOverlayInterval;
        if (currentPhaseIsDeadline) {
          if (timeDifferenceInMinutes >= 60) showPaymentOverlay();
          else
            reOpenOverlayInterval = setInterval(
              function reOpenPaymentOverlay() {
                showPaymentOverlay();
              },
              3600000
            ); //3600000 ms = 1 hour
        }
        return () => {
          clearInterval(reOpenOverlayInterval);
        };
      }
    },
    [currentPhaseIsDeadline, currentPhaseIsGrace]
  );

  return (
    <Backdrop
      open={openPaymentOverlay}
      sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
    >
      <Grid
        container
        display="flex"
        flexDirection="column"
        gap="20px"
        padding="32px"
        bgcolor={primaryColors.base.white}
        width={{ xs: "85%", md: "55%" }}
        borderRadius="4px"
      >
        <Grid
          item
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          gap={2}
          alignItems="center"
          color="#B42318"
        >
          <ErrorIcon />
          <Typography
            sx={{
              //   color: primaryColors.gray[500],
              fontSize: fontSizeVariations["text-sm"],
              fontWeight: fontWeightVariations["medium"],
            }}
          >
            {currentPhaseIsGrace
              ? gracePaymentStatusMessage
              : currentPhaseIsDeadline
              ? deadlinePaymentStatusMessage
              : ""}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            sx={{
              color: primaryColors.gray[500],
              fontSize: fontSizeVariations["text-sm"],
              fontWeight: fontWeightVariations["medium"],
              visibility: !buttonsEnabled ? "visible" : "hidden",
            }}
          >
            You will be back to your dashboard in
            <span style={{ color: primaryColors.brand[600] }}>
              {` ${overlayClosingCountdown} `}
            </span>
            seconds.
          </Typography>
        </Grid>

        <Grid
          item
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Button
            variant="outlined"
            disabled={!buttonsEnabled}
            onClick={() => {
              startOverlayCountdownAndRedirectIfNeeded({
                redirectToFeedbackTab: true,
              });
            }}
            sx={{
              width: "100%",
              padding: "10px 18px",
              borderRadius: "8px",
              border: `1px solid ${primaryColors.gray[300]}`,
            }}
          >
            <Typography
              sx={{
                textTransform: "none",
                color: primaryColors.gray[700],
                fontSize: fontSizeVariations["text-md"],
                fontWeight: fontWeightVariations["semiBold"],
              }}
            >
              Get in touch
            </Typography>
          </Button>
          <Button
            variant="outlined"
            disabled={!buttonsEnabled}
            onClick={() => {
              startOverlayCountdownAndRedirectIfNeeded({
                redirectToFeedbackTab: false,
              });
            }}
            sx={{
              width: "100%",
              padding: "10px 18px",
              borderRadius: "8px",
              border: `1px solid ${primaryColors.brand[500]}`,
              backgroundColor: primaryColors.brand[500],
              "&:hover": {
                backgroundColor: primaryColors.brand[500],
              },
            }}
          >
            <Typography
              sx={{
                textTransform: "none",
                color: primaryColors.base.white,
                fontSize: fontSizeVariations["text-md"],
                fontWeight: fontWeightVariations["semiBold"],
              }}
            >
              I acknowledge
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Backdrop>
  );
};
